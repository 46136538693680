import { i18next } from '@ali/dingtalk-i18n';
import { Message } from '@ali/we-design-next';
import { useMemoizedFn, useMount, useUnmount } from 'ahooks';
import React, { useRef, useState } from 'react';
import { sendSMS } from '../../../services';
import { CaptureElement } from './styles';

declare global {
  interface Window {
    // 阿里云人机验证 sdk
    initAliyunCaptcha: any;
    __baxia__: any;
  }
}

export const CAPTCHA_BUTTON_ID = 'captcha-button';
export const CAPTCHA_ELEMENT_ID = 'captcha-element';

type UseCaptchaProps = {
  onSuccess: (verificationSessionId: string) => void;
  phoneNumber: string;
  scene: 'login' | 'accept_invitation',
  handshakeId?: string
}

// TODO: @maoq 可以通过 captcha.destroyCaptcha() 来销毁，重新初始化 captcha
export const useCaptcha = ({ onSuccess, phoneNumber, scene, handshakeId }: UseCaptchaProps) => {
  const [showCaptcha, setShowCaptcha] = useState(false);

  const captcha = useRef<any>();
  const verificationSessionId = useRef('');

  const getInstance = useMemoizedFn((instance: any) => {
    captcha.current = instance;
  });

  const captchaVerifyCallback = useMemoizedFn(async (captchaVerifyParam: any) => {
    const phoneNum = phoneNumber;
    // 1.向后端发起业务请求，获取验证码验证结果和业务结果
    try {
      const result = await sendSMS({
        phoneNumber: phoneNum,
        captchaVerifyParam,
        scene,
        handshakeId,
      });
      if (result.sendSmsSuccess) {
        verificationSessionId.current = result.verificationSessionId;
      }

      return {
        captchaResult: result.nextAction?.type !== 'captcha',
        bizResult: result.sendSmsSuccess,
      };
    } catch (e: any) {
      Message.error(e.errorMessage || i18next.t('发送失败'));
      return {
        captchaResult: true,
        bizResult: false,
      };
    }
  });
  // 验证通过后调用
  const onBizResultCallback = useMemoizedFn((bizResult: boolean) => {
    if (bizResult) {
      Message.success(i18next.t('已发送验证码'));
      onSuccess(verificationSessionId.current);
    }
    setShowCaptcha(false);
  });

  useMount(() => {
    if (window.__baxia__ && window.__baxia__.options) {
      window.__baxia__.options.addVersionToUrl = false;
    }
    window.initAliyunCaptcha({
      SceneId: '1j5yybc7', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: '3jg9db', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: 'embed', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: `#${CAPTCHA_ELEMENT_ID}`, // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: `#${CAPTCHA_BUTTON_ID}`, // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback, // 业务请求结果回调函数，无需修改
      getInstance, // 绑定验证码实例函数，无需修改
      slideStyle: {
        width: 320,
        height: 40,
      }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
      immediate: true,
    });
  });

  useUnmount(() => {
    // 必须删除相关元素，否则再次mount多次调用 initAliyunCaptcha 会导致多次回调 captchaVerifyCallback
    captcha.current?.destroyCaptcha();
    document.getElementById('aliyunCaptcha-mask')?.remove();
    document.getElementById('aliyunCaptcha-window-popup')?.remove();
  });

  const handleCaptcha = useMemoizedFn(() => {
    document.getElementById(CAPTCHA_BUTTON_ID)?.click();
    setShowCaptcha(true);
    captcha.current?.show();
  });

  const renderCaptcha = () => {
    return (
      <CaptureElement
        id={CAPTCHA_ELEMENT_ID}
        style={{ display: showCaptcha ? 'block' : 'none' }}
        className={showCaptcha ? 'show' : 'hide'}
      />
    );
  };

  return {
    handleCaptcha,
    renderCaptcha,
    captchaShown: showCaptcha,
  };
};
