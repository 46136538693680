import { COLORS } from '@ali/we-design-token';
import styled, { createGlobalStyle } from 'styled-components';

const rootId = 'root';

export const GlobalStyle = createGlobalStyle`
  @media (max-height: 600px) {
    #${rootId} {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 600px;
  background: #f2f9fe;
`;

export const BGImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .left {
    position: absolute;
    left: 0;
    top: 0;

    img {
      width: 176px;
    }
  }

  .right {
    position: absolute;
    right: 0;
    bottom: 0;

    img {
      width: 270px;
    }
  }
`;

export const TitleBar = styled.img`
  height: 60px;
  margin-bottom: 40px;
`;

export const ContentWrapper = styled.div`
  width: 420px;
  max-width: calc(100vw - 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% - 36px));

  @media (max-height: 680px) {
    ${TitleBar} {
      display: none;
    }

    & {
      transform: translate(-50%, -50%);
    }
  }
`;

export const Card = styled.div`
  position: relative;
  width: 100%;
  height: 520px;
  background-color: ${COLORS.FG_HEAVY};
  border: 1px solid ${COLORS.LINE_LIGHT};
  box-shadow: var(--we_xs_shadow, 0 1px 2px 0 rgba(0, 0, 0, 0.04));
  border-radius: 12px;
  padding: 8vh 0 4.6vh 0;
  display: flex;
  justify-content: center;
`;
