import { COLORS } from '@ali/we-design-token';
import styled from 'styled-components';

export const CaptureElement = styled.div`
  &.show:not(:empty) {
    margin-top: 12px;
  }

  /* stylelint-disable-next-line selector-max-id */
  #aliyunCaptcha-sliding-body {
    box-sizing: content-box;
  }

  /* stylelint-disable-next-line selector-max-id */
  #aliyunCaptcha-sliding-wrapper #aliyunCaptcha-sliding-body #aliyunCaptcha-sliding-slider {
    background: ${COLORS.FG_LIGHT};
    color: ${COLORS.LEVEL4};

    /* stylelint-disable-next-line selector-max-id */
    &.ok {
      color: ${COLORS.FG_LIGHT};
    }
  }
`;

export const CaptchaBtn = styled.button`
  visibility: hidden;
  position: fixed;
  left: -9999px;
`;
