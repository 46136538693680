import React from 'react';
import { Input } from '@ali/we-design-next';
import { FormItemWrapper } from './styles';

interface Props {
  label: string;
  placeholder: string;
  required?: boolean;
  value: string;
  onChange: (val: string) => void;
}

export const FormItem = ({ label, placeholder, required, onChange, value }: Props) => {
  return (
    <FormItemWrapper>
      <div className="label">
        {required ? <span className="required">*</span> : null }
        {label}
      </div>
      <Input placeholder={placeholder} onChange={(e) => onChange(e.target.value)} value={value} />
    </FormItemWrapper>
  );
};
