import React from 'react';
import { BGImage, Card, Container, ContentWrapper, GlobalStyle, TitleBar } from './styles';

export { CardLayout } from './CardLayout';

interface Props {
  children: React.ReactNode;
}

const titleImg
  = 'https://img.alicdn.com/imgextra/i2/O1CN01uhuPao1qWsuuNt0Xj_!!6000000005504-2-tps-534-120.png';

export const HomePageLayout = ({ children }: Props) => {
  return (
    <Container>
      <GlobalStyle />
      <BGImage>
        <div className="left">
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01jqMuaL1fLTHLZMFDU_!!6000000003990-2-tps-528-975.png"
            alt=""
          />
        </div>
        <div className="right">
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01KLOXRe1aKULkzcSBD_!!6000000003311-2-tps-810-912.png"
            alt=""
          />
        </div>
      </BGImage>
      <ContentWrapper>
        <TitleBar src={titleImg} />
        <Card>{children}</Card>
      </ContentWrapper>
    </Container>
  );
};
