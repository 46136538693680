import { COLORS } from '@ali/we-design-token';
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0%, 50% {
    opacity: 1;
  }
  50.01%, 100% {
    opacity: 0;
  }
`;

export const VerifyCodeInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 48px;
  width: 272px;

  .verify-code-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 4px;
    background-color: ${COLORS.LEVEL5};
    flex: 1;
    font-size: 16px;
    min-width: 0;
  }

  .active::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: 1px;
    height: 1em;
    transform: translate(-50%, -50%);
    background-color: ${COLORS.LEVEL1};
    animation: ${blink} 1s steps(1) infinite;
  }

  .hidden-input {
    position: absolute;
    opacity: 0;
    left: -9999px; /* 将元素移出视口 */
  }
`;
