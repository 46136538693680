import React, { memo, useState } from 'react';
import { OrgInfoVO } from '@ali/dingdoc-portal-data';
import { useMount } from 'ahooks';
import { Spin } from '@ali/we-design-next';
import { GlobalStyle } from '../../styles';
import { AppCommonEntry } from '../components/AppCommonEntry';
import { WdnConfigProvider } from '../components/WdnConfigProvider';
import { HomePageLayout } from '../components/HomePageLayout';
import { CAPTCHA_BUTTON_ID, CaptchaBtn } from '../components/Captcha';
import { checkLoginStatus } from '../../services/service/login';
import { PhoneLogin } from './components/PhoneLogin';
import { SMSValid } from './components/SMSValid';
import { SMSValidInfo } from './types';
import { OrgPick } from './components/OrgPick';
import { CreateOrg } from './components/CreateOrg';


enum Step {
  PHONE_LOGIN,
  SMS_VALID,
  ORG_PICK,
  ORG_CREATE,
}

export const LoginApp = memo(() => {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState<Step>(Step.PHONE_LOGIN);
  const [smsValidInfo, setSmsValidInfo] = useState<SMSValidInfo>();
  const [orgList, setOrgList] = useState<OrgInfoVO[]>([]);

  useMount(async () => {
    try {
      const { isLogin } = await checkLoginStatus();
      if (isLogin) {
        setStep(Step.ORG_PICK);
      }
    } finally {
      setLoading(false);
    }
  });

  const onSendSMS = (opts: SMSValidInfo) => {
    setSmsValidInfo(opts);
    setStep(Step.SMS_VALID);
  };

  const onSMSValidBack = () => {
    setStep(Step.PHONE_LOGIN);
    setSmsValidInfo(undefined);
  };

  const onSMSValidFinish = (orgList: OrgInfoVO[]) => {
    setStep(Step.ORG_PICK);
    setOrgList(orgList);
  };

  const onOrgPickBack = () => {
    setStep(Step.PHONE_LOGIN);
    setSmsValidInfo(undefined);
  };

  const onCreateOrg = () => {
    setStep(Step.ORG_CREATE);
  };

  const onOrgCreateBack = () => {
    setStep(Step.ORG_PICK);
  };

  const renderCardContent = () => {
    switch (step) {
      case Step.PHONE_LOGIN:
        return <PhoneLogin onSendSMS={onSendSMS} />;
      case Step.SMS_VALID:
        if (smsValidInfo) {
          return <SMSValid {...smsValidInfo} onBack={onSMSValidBack} onFinish={onSMSValidFinish} />;
        }
        return null;
      case Step.ORG_PICK:
        return <OrgPick orgList={orgList} onBack={onOrgPickBack} onCreateOrg={onCreateOrg} />;
      case Step.ORG_CREATE:
        return <CreateOrg onBack={onOrgCreateBack} />;
      default:
        return null;
    }
  };

  return (
    <>
      <GlobalStyle />
      <WdnConfigProvider>
        <AppCommonEntry>
          <>
            <HomePageLayout>
              {loading ? <Spin /> : renderCardContent()}
            </HomePageLayout>
            <CaptchaBtn id={CAPTCHA_BUTTON_ID} />
          </>
        </AppCommonEntry>
      </WdnConfigProvider>
    </>
  );
});
