import {
  baseHttpFactory,
  type HttpInstance,
  type RequestAxiosInterceptorMakers,
} from '@ali/dingdoc-portal-data/lib/request/core';
import { makeSureCsrfToken } from '@ali/dingdoc-portal-data/lib/request/helpers/csrf-token';
import { getAxiosBaseUrl } from '@ali/dingdoc-portal-data/lib/request/helpers/get-axios-base-url';
import {
  enrichRequestHeaders,
  responseErrorMessageMap,
  unauthorizedRetry,
  devRequestNetworkBlocker,
} from '@ali/dingdoc-portal-data/lib/request/adapters/cloud-space-http-factory/interceptors';
import { logError, logResponse } from './interceptors/log';

makeSureCsrfToken();

const IS_DEBUG = process.env.NODE_ENV !== 'production';

export const httpFactory = (enableAToken = true): HttpInstance => {
  const requestInterceptorMakers: RequestAxiosInterceptorMakers[] = enableAToken
    ? [
      [enrichRequestHeaders],
    ]
    : [];
  if (IS_DEBUG) {
    requestInterceptorMakers.push([devRequestNetworkBlocker]);
  }

  const baseURL = getAxiosBaseUrl();
  return baseHttpFactory<HttpInstance>({
    xsrfConfig: {
      xsrfCookieName: 'XSRF-TOKEN',
      xsrfHeaderName: 'X-XSRF-TOKEN',
    },
    baseURL: baseURL || undefined,
    requestInterceptorMakers,
    responseInterceptorMakers: [
      [undefined, unauthorizedRetry],
      [undefined, responseErrorMessageMap],
      [logResponse, logError],
    ],
  });
};
