import type {
  CheckLoginStatus,
  CreateOrg,
  CreateOrgRes,
  Login,
  LoginRes,
  SendSMS,
  SendSMSRes,
} from '../interfaces/login';
import { requestWithoutAToken } from '../request';

const transformLoginRes = (data: any): LoginRes => {
  return {
    orgList: data?.orgList || [],
    redirectUrl: data?.redirectUrl || '',
  };
};
export const login: Login = async (params) => {
  let isRedirect = false;
  const res = await requestWithoutAToken.post('/one/api/v1/login', {
    ...params,
    loginMode: 'validationCode',
  });

  const { isSuccess, data }: { isSuccess: boolean; data: any } = res || {};

  if (!isSuccess || !data) {
    throw new Error(res?.tips || '异常错误');
  }

  if (data.redirectUrl) {
    window.location.href = data.redirectUrl;
    isRedirect = true;
  }

  if (isRedirect) {
    return {
      orgList: [],
      redirectUrl: '',
      redirecting: true,
    };
  }

  return transformLoginRes(data);
};

const transformSendSMSRes = (data: any): SendSMSRes => {
  return {
    nextAction: data.nextAction || undefined,
    sendSmsSuccess: data.sendSmsSuccess || false,
    expire: data.expire || 0,
    verificationSessionId: data.verificationSessionId || '',
  };
};
export const sendSMS: SendSMS = async (params) => {
  const res = await requestWithoutAToken.post('/one/api/v1/sms/validation_code', {
    phoneNumber: params.phoneNumber,
    scene: params.scene,
    handshakeId: params.handshakeId,
    captchaParam: params.captchaVerifyParam
      ? {
        captchaVerifyParam: params.captchaVerifyParam,
      }
      : undefined,
  });
  const { isSuccess, data }: { isSuccess: boolean; data: any } = res || {};
  if (!isSuccess || !data) {
    throw new Error(res?.tips || '异常错误');
  }
  return transformSendSMSRes(data);
};

const transformCreateOrgRes = (data: any): CreateOrgRes => {
  return {
    corpId: data.corpId || '',
    name: data.name || '',
  };
};
export const createOrg: CreateOrg = async (params) => {
  const res = await requestWithoutAToken.post('/one/api/v1/org/create', {
    name: params.orgName,
    userName: params.userName,
  });
  const { isSuccess, data }: { isSuccess: boolean; data: any } = res || {};
  if (!isSuccess || !data) {
    throw new Error(res?.tips || '异常错误');
  }
  return transformCreateOrgRes(data);
};

export const checkLoginStatus: CheckLoginStatus = async () => {
  const res = await requestWithoutAToken.get('/api/login/status');
  const { isSuccess, data }: { isSuccess: boolean; data: any } = res || {};
  if (!isSuccess || !data) {
    throw new Error(res?.tips || '异常错误');
  }
  return {
    isLogin: !!data.isLogin,
  };
};
