import { COLORS } from '@ali/we-design-token';
import React from 'react';
import styled from 'styled-components';
import { PRIVACY_POLICY_URL, SERVICE_AGREEMENT_URL } from '../../../common/resource';

const ProtocolTextUI = styled.span`
  color: ${COLORS.LEVEL2};

  a {
    &,
    &:hover {
      text-decoration: none;
      color: ${COLORS.BLUE1};
    }
  }
`;

export const ProtocolText = () => {
  return (
    <ProtocolTextUI>
      我已阅读并同意&nbsp;
      <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">隐私政策</a>
      &nbsp;与&nbsp;
      <a href={SERVICE_AGREEMENT_URL} target="_blank" rel="noreferrer">服务协议</a>
    </ProtocolTextUI>
  );
};
