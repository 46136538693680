import { i18next } from '@ali/dingtalk-i18n';
import React, { useEffect, useRef, useState } from 'react';
import { useMemoizedFn } from 'ahooks';
import { debounce } from 'lodash';
import { OrgInfoVO } from '@ali/dingdoc-portal-data';
import { Message } from '@ali/we-design-next';
import cx from 'classnames';
import { CardLayout } from '../../../components/HomePageLayout';
import { SMSValidInfo } from '../../types';
import { login } from '../../../../services';
import { useCaptcha } from '../../../components/Captcha';
import { VerifyCodeInput, VerifyCodeInputRef } from '../../../components/VerifyCodeInput';
import { CountdownWrapper } from './styles';

interface Props extends SMSValidInfo {
  onBack: () => void;
  onFinish: (orgList: OrgInfoVO[]) => void;
}

const COUNTDOWN_TIME = 60;

export const SMSValid = ({
  verificationSessionId,
  areaCode,
  phoneNumber,
  onBack,
  onFinish,
}: Props) => {
  const [countdown, setCountdown] = useState(COUNTDOWN_TIME);
  const [currentVerificationSessionId, setCurrentVerificationSessionId] = useState(verificationSessionId);
  const [requesting, setRequesting] = useState(false);

  const verifyCodeInputRef = useRef<VerifyCodeInputRef>(null);
  const [logining, setLogining] = useState(false);

  const onSuccess = useMemoizedFn((verificationSessionId: string) => {
    setCountdown(COUNTDOWN_TIME);
    setCurrentVerificationSessionId(verificationSessionId);
    verifyCodeInputRef.current?.reset();
  });

  const { handleCaptcha, renderCaptcha, captchaShown } = useCaptcha({
    phoneNumber,
    onSuccess,
    scene: 'login',
  });
  const _debouncedHandleCaptcha = useMemoizedFn(
    debounce(() => {
      handleCaptcha();
    }, 100),
  );


  const handleCodeComplete = useMemoizedFn(async (values: string[]) => {
    try {
      setLogining(true);
      const res = await login({
        phoneNumber,
        verificationSessionId: currentVerificationSessionId,
        validationCode: values.join(''),
      });
      if (!res.redirecting) {
        onFinish(res.orgList);
      }
    } catch (e: any) {
      Message.error(e.errorMessage || i18next.t('登录失败'));
    } finally {
      setLogining(false);
    }
  });

  const handleSendSMS = useMemoizedFn(() => {
    if (requesting) {
      return;
    }
    setRequesting(true);
    _debouncedHandleCaptcha();
  });

  useEffect(() => {
    if (countdown !== 0) {
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
  }, [countdown]);

  useEffect(() => {
    if (captchaShown) {
      setRequesting(false);
    }
  }, [captchaShown]);

  const content = (
    <>
      <VerifyCodeInput
        ref={verifyCodeInputRef}
        onComplete={handleCodeComplete}
        disabled={logining}
        style={{ marginTop: 40, width: '100%' }}
      />
      <CountdownWrapper>
        {renderCaptcha()}
        {countdown === 0
          ? (
            <>
              <span
                className={cx('resend-sms', {
                  disabled: requesting,
                })}
                onClick={handleSendSMS}
              >
                {i18next.t('重新获取验证码')}
              </span>
            </>
          )
          : (
            <>
              <span className="countdown">{i18next.t(`${countdown}秒后重新获取验证码`)}</span>
            </>
          )}
      </CountdownWrapper>
    </>
  );

  return (
    <CardLayout
      title={i18next.t('输入验证码')}
      desc={i18next.t(`验证码已发送至 ${areaCode} ${phoneNumber}`)}
      content={content}
      showBack
      onBack={onBack}
    />
  );
};
