import React, { useMemo } from 'react';
import { ConfigProvider } from '@ali/we-design-next';
import { Locale } from '@ali/we-design-next/types/locale';
import { getCurrentLanguage, Language } from '@ali/dingtalk-i18n';

import '@ali/we-design-next/locale/en-US';
import '@ali/we-design-next/locale/zh-CN';
import '@ali/we-design-next/locale/zh-HK';
import '@ali/we-design-next/locale/zh-TW';
import '@ali/we-design-next/locale/ja-JP';
import '@ali/we-design-next/locale/ko-KR';
import '@ali/we-design-next/locale/pt-BR';
import '@ali/we-design-next/locale/ru-RU';
import '@ali/we-design-next/locale/tr-TR';
import '@ali/we-design-next/locale/fr-FR';
import '@ali/we-design-next/locale/es-LA';
import '@ali/we-design-next/locale/th-TH';
import '@ali/we-design-next/locale/id-ID';
import '@ali/we-design-next/locale/ms-MY';

const AcceptLanguage = [
  'zh-CN',
  'zh-HK',
  'zh-TW',
  'ja-JP',
  'en-US',
  'ko-KR',
  'pt-BR',
  'ru-RU',
  'tr-TR',
  'fr-FR',
  'es-LA',
  'th-TH',
  'id-ID',
  'ms-MY',
];

export const WdnConfigProvider: React.FC<{ theme?: 'light' | 'dark'; }> = (props) => {
  const weDesignLang = useMemo(() => {
    const lang = Language[getCurrentLanguage()].replace('_', '-');
    if (AcceptLanguage.includes(lang)) {
      return lang as Locale;
    }
    return 'en-US';
  }, []);

  return (
    <ConfigProvider value={{ locale: weDesignLang, theme: 'classic', mode: props.theme }}>
      {props.children}
    </ConfigProvider>
  );
};
