// import type { AxiosRequestHeaders, AxiosResponseHeaders } from 'axios';
import type { ResponseAxiosInterceptorMakers } from '@ali/dingdoc-portal-data/lib/request/core';
// import { logger } from '../../../../common/utils/logger';

// function getTraceId(headers?: AxiosResponseHeaders | AxiosRequestHeaders) {
//   for (const key in headers) {
//     if (key?.toLowerCase?.() === 'eagleeye-traceid' && headers[key]) {
//       return `${headers[key]}`;
//     }
//   }
//   return '';
// }

// function getTraceIdFromRequest(request: any) {
//   return request?.__recordInfo__?.traceId || '';
// }

export const logResponse: ResponseAxiosInterceptorMakers[0] = () => (value) => {
  // const { method, url, headers, params, data } = value?.config || {};

  // if (url) {
  //   logger.api({
  //     requestType: 'xhr',
  //     method: method || '',
  //     api: url,
  //     url,
  //     traceId: getTraceId(value?.headers) || getTraceId(headers) || getTraceIdFromRequest(value?.request),
  //     success: true,
  //     httpCode: value?.status,
  //     requestHeader: JSON.stringify(headers),
  //     params: JSON.stringify({ params, data }),
  //     responseHeader: JSON.stringify(value?.headers),
  //   });
  // }
  return value;
};

export const logError: ResponseAxiosInterceptorMakers[1] = () => (error) => {
  // const { method, url, headers, params, data } = error?.config || {};
  // if (url) {
  //   logger.api({
  //     requestType: 'xhr',
  //     method: method || '',
  //     api: url,
  //     url,
  //     traceId: getTraceId(error?.response?.headers) || getTraceId(headers) || getTraceIdFromRequest(error?.request),
  //     success: false,
  //     errCode: error?.status,
  //     httpCode: error?.status,
  //     requestHeader: JSON.stringify(headers),
  //     params: JSON.stringify({ params, data }),
  //     responseHeader: JSON.stringify(error?.response?.headers),
  //     response: JSON.stringify(error?.response?.data),
  //   });
  // }
  return Promise.reject(error);
};
