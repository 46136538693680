import styled from 'styled-components';
import { COLORS } from '@ali/we-design-token';
import { CAPTCHA_ELEMENT_ID } from '../../../components/Captcha';

export const CountdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-top: 12px;

  #${CAPTCHA_ELEMENT_ID}.show:not(:empty) + * {
    display: none;
  }

  .countdown {
    color: ${COLORS.LEVEL4};
  }

  .resend-sms {
    cursor: default;
    color: ${COLORS.BLUE1};

    &.disabled {
      opacity: 0.5;
    }
  }
`;
