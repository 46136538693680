import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { HttpInstance } from '@ali/dingdoc-portal-data/lib/request/core';
import { httpFactory } from './adapters';

export const httpInstance: HttpInstance = httpFactory();

const pickAxiosResponseData = async <T>(resp: Promise<AxiosResponse<T>>) => {
  const res = await resp;
  return res ? res.data : res;
};

export const request = {
  get: async <T = any>(url: string, params?: any, headers?: any, options?: AxiosRequestConfig) => {
    return await pickAxiosResponseData(httpInstance.get<T>(url, { params, headers, ...options }));
  },
  post: async <T = any>(url: string, data?: any, headers?: any, options?: AxiosRequestConfig) => {
    return await pickAxiosResponseData(httpInstance.post<T>(url, data, { headers, ...options }));
  },
  put: async <T = any>(url: string, data?: any, headers?: any) => {
    return await pickAxiosResponseData(httpInstance.put<T>(url, data, { headers }));
  },
  delete: async <T = any>(url: string, params?: any, headers?: any, options?: AxiosRequestConfig) => {
    return await pickAxiosResponseData(httpInstance.delete<T>(url, { params, headers, ...options }));
  },
};

export const httpInstanceWithoutAToken: HttpInstance = httpFactory(false);

export const requestWithoutAToken = {
  get: async <T = any>(url: string, params?: any, headers?: any, options?: AxiosRequestConfig) => {
    return await pickAxiosResponseData(httpInstanceWithoutAToken.get<T>(url, { params, headers, ...options }));
  },
  post: async <T = any>(url: string, data?: any, headers?: any, options?: AxiosRequestConfig) => {
    return await pickAxiosResponseData(httpInstanceWithoutAToken.post<T>(url, data, { headers, ...options }));
  },
  put: async <T = any>(url: string, data?: any, headers?: any) => {
    return await pickAxiosResponseData(httpInstanceWithoutAToken.put<T>(url, data, { headers }));
  },
  delete: async <T = any>(url: string, params?: any, headers?: any, options?: AxiosRequestConfig) => {
    return await pickAxiosResponseData(httpInstanceWithoutAToken.delete<T>(url, { params, headers, ...options }));
  },
};
