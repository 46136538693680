import { Button, Input } from '@ali/we-design-next';
import styled from 'styled-components';
import { COLORS } from '@ali/we-design-token';
import { CAPTCHA_ELEMENT_ID } from '../../../components/Captcha';

export const PhoneInputPrefix = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 8px;

  .divider {
    width: 1px;
    height: 16px;
    background-color: ${COLORS.LINE_HEAVY};
    margin-left: 12px;
    margin-right: 8px;
  }
`;

export const AreaCode = styled.div`
  color: ${COLORS.LEVEL1};
`;

export const PhoneInput = styled(Input)`
  &.wdn-input {
    width: 100%;
    height: 48px;
    margin-top: 40px;
    background-color: ${COLORS.LEVEL5};
    border-color: transparent;

    &:focus-within {
      box-shadow: none;
    }
    
    &, input {
      font-size: 16px;
      line-height: 24px;
      color: ${COLORS.LEVEL1};
    }
  }
`;

export const SendSMSBtn = styled(Button)`
  &.wdn-button {
    height: 48px;
    width: 100%;
    justify-content: center;
    margin-top: 12px;
    font-size: 16px;

    .wdn-spin {
      margin-right: 8px;
    }
  }
  
  #${CAPTCHA_ELEMENT_ID}.show:not(:empty) + & {
    display: none;
  }
`;
