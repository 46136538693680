import { OrgInfoVO } from '@ali/dingdoc-portal-data';
import React from 'react';
import { i18next } from '@ali/dingtalk-i18n';
import { DEFAULT_ORG_ICON } from '@ali/dingdoc-portal-common';
import { Goback20 } from '@ali/we-icons';
import { CardLayout } from '../../../components/HomePageLayout';
import { OrgList, OrgWrapper } from './styles';

interface Props {
  orgList: OrgInfoVO[];
  onBack: () => void;
  onCreateOrg: () => void;
}

export const OrgPick = ({ orgList, onBack, onCreateOrg }: Props) => {
  const content = (
    <OrgList>
      <OrgWrapper onClick={onCreateOrg}>
        <img src={DEFAULT_ORG_ICON} alt="" className="logo" />
        <div className="org-info">
          <div className="title">{i18next.t('创建企业')}</div>
          <div className="desc">{i18next.t('创建并邀请成员加入，开启高效协作')}</div>
        </div>
        <Goback20 className="icon-go" />
      </OrgWrapper>
    </OrgList>
  );

  if (!orgList.length) {
    return (
      <CardLayout
        title={i18next.t('你暂未加入任何企业')}
        desc={i18next.t('创建企业以继续使用')}
        content={content}
        showBack
        onBack={onBack}
      />
    );
  }

  return null;
};
