import { TextButton } from '@ali/we-design-next';
import { COLORS } from '@ali/we-design-token';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: ${COLORS.LEVEL1};
`;

export const Desc = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.LEVEL3};
  margin-top: 4px;
`;

export const BackBtn = styled(TextButton)`
  &.wdn-text-button {
    position: absolute;
    left: 12px;
    top: 20px;
    color: ${COLORS.LEVEL2};

    & > div {
      display: flex;
      align-items: center;
    }
  }
`;
