import React, { useState } from 'react';
import { i18next } from '@ali/dingtalk-i18n';
import { Message } from '@ali/we-design-next';
import { CardLayout } from '../../../components/HomePageLayout';
import { createOrg } from '../../../../services/service/login';
import { FormItem } from './FormItem';
import { CreateBtn, FormWrapper } from './styles';

interface Props {
  onBack: () => void;
}

export const CreateOrg = ({ onBack }: Props) => {
  const [orgName, setOrgName] = useState('');
  const [creator, setCreator] = useState('');

  const handleCreateOrg = async () => {
    try {
      await createOrg({
        orgName,
        userName: creator,
      });
      window.location.href = '/i/enterprise_activation';
    } catch (e: any) {
      Message.error(e.errorMessage || i18next.t('创建失败'));
    }
  };

  const content = (
    <FormWrapper>
      <FormItem
        label={i18next.t('企业名称')}
        placeholder={i18next.t('输入企业名称')}
        value={orgName}
        onChange={setOrgName}
        required
      />
      <FormItem
        label={i18next.t('创建人名称')}
        placeholder={i18next.t('输入你的姓名')}
        value={creator}
        onChange={setCreator}
      />
    </FormWrapper>
  );

  const footer = (
    <CreateBtn disabled={!orgName} type="primary" onClick={handleCreateOrg}>
      {i18next.t('创建企业')}
    </CreateBtn>
  );

  return (
    <CardLayout
      title={i18next.t('填写企业信息')}
      desc={i18next.t('创建企业，开启高效协作')}
      content={content}
      footer={footer}
      showBack
      onBack={onBack}
    />
  );
};
