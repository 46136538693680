import '@ali/we-design-token/styles/token.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { autoInit } from '@ali/dingtalk-i18n';
import resource from '../../i18nResource/translation';
import { LoginApp } from '../apps/Login';

export const run = () => {
  autoInit(resource);

  ReactDOM.render(
    React.createElement(LoginApp),
    document.getElementById('root'),
  );
};

run();
