import React from 'react';
import { LeftBack20 } from '@ali/we-icons';
import { i18next } from '@ali/dingtalk-i18n';
import { BackBtn, Body, Desc, Footer, Title, Wrapper } from './styles';

interface Props {
  title: string;
  desc: string;
  content: React.ReactNode;
  footer?: React.ReactNode;
  showBack?: boolean;
  onBack?: () => void;
}

export const CardLayout = ({ title, desc, content, footer, showBack, onBack }: Props) => {
  return (
    <Wrapper>
      <Body>
        {showBack
          ? (
            <BackBtn onClick={onBack}>
              <LeftBack20 size={16} />
              {i18next.t('返回')}
            </BackBtn>
          )
          : null}
        <Title>{title}</Title>
        <Desc>{desc}</Desc>
        {content}
      </Body>
      {footer ? <Footer>{footer}</Footer> : null}
    </Wrapper>
  );
};
