import { COLORS } from '@ali/we-design-token';
import styled from 'styled-components';

export const OrgList = styled.div`
width: 100%;
margin-top: 32px;
`;

export const OrgWrapper = styled.div`
  width: 100%;
  height: 70px;
  border-radius: 6px;
  background-color: ${COLORS.FG_HIGHLIGHT};
  padding: 12px;
  display: flex;
  align-items: center;

  .logo {
    width: 40px;
    height: 40px;
  }

  .org-info {
    margin-left: 12px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow: hidden;

    .title {
      font-size: 16px;
      line-height: 24px;
      color: ${COLORS.LEVEL1};
      font-weight: 500;
    }

    .desc {
      font-size: 13px;
      line-height: 20px;
      color: ${COLORS.LEVEL2};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .icon-go {
    transform: rotate(180deg);
    color: ${COLORS.LEVEL3};
    flex-shrink: 0;
  }
`;
