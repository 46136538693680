import React, { ChangeEvent, useEffect, useState } from 'react';
import { i18next } from '@ali/dingtalk-i18n';
import { useMemoizedFn } from 'ahooks';
import { Checkbox, Modal, Spin } from '@ali/we-design-next';
import { debounce } from 'lodash';
import { CardLayout } from '../../../components/HomePageLayout';
import { SMSValidInfo } from '../../types';
import { useCaptcha } from '../../../components/Captcha';
import { ProtocolText } from '../../../components/ProtocolText';
import { AreaCode, PhoneInput, PhoneInputPrefix, SendSMSBtn } from './styles';

declare global {
  interface Window {
    // 阿里云人机验证 sdk
    initAliyunCaptcha: any;
  }
}

interface Props {
  onSendSMS: (options: SMSValidInfo) => void;
}

export const PhoneLogin = ({ onSendSMS }: Props) => {
  const [phoneNum, setPhoneNum] = useState('');
  const [protocolChecked, setProtocolChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const onValidSuccess = useMemoizedFn((verificationSessionId: string) => {
    onSendSMS({
      phoneNumber: phoneNum,
      verificationSessionId,
      areaCode: '+86',
    });
  });

  const { renderCaptcha, handleCaptcha, captchaShown } = useCaptcha({
    phoneNumber: phoneNum,
    onSuccess: onValidSuccess,
    scene: 'login',
  });

  useEffect(() => {
    if (!captchaShown) {
      setRequesting(false);
    }
  }, [captchaShown]);

  const _debouncedHandleCaptcha = useMemoizedFn(
    debounce(() => {
      handleCaptcha();
    }, 100),
  );

  const handleChangePhoneNum = useMemoizedFn((e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setPhoneNum(val);
  });

  const handleSendSMS = useMemoizedFn((force: boolean = false) => {
    if (!force && !protocolChecked) {
      setShowModal(true);
      return;
    }
    setRequesting(true);
    _debouncedHandleCaptcha();
  });

  const InputPrefix = (
    <PhoneInputPrefix>
      <AreaCode>+86</AreaCode>
      <div className="divider" />
    </PhoneInputPrefix>
  );

  return (
    <>
      <CardLayout
        title={i18next.t('欢迎使用钉钉文档')}
        desc={i18next.t('请输入手机号登录')}
        content={
          <>
            <PhoneInput
              prefix={InputPrefix}
              value={phoneNum}
              onChange={handleChangePhoneNum}
              placeholder={i18next.t('输入手机号')}
              size="large"
              allowClear
            />
            {renderCaptcha()}
            <SendSMSBtn
              type="primary"
              disabled={!phoneNum || requesting}
              onClick={() => handleSendSMS()}
            >
              {requesting ? <Spin /> : null}
              {i18next.t('发送验证码')}
            </SendSMSBtn>
          </>
        }
        footer={
          <Checkbox
            checked={protocolChecked}
            onChange={(e) => setProtocolChecked(e.target.checked)}
          >
            <ProtocolText />
          </Checkbox>
        }
      />
      <Modal
        style={{ minWidth: 320 }}
        title={i18next.t('服务协议及隐私保护')}
        content={<ProtocolText />}
        mask
        visible={showModal}
        onClose={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        onConfirm={() => {
          setProtocolChecked(true);
          setShowModal(false);
          handleSendSMS(true);
        }}
      />
    </>
  );
};
