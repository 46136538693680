/** logo */
export const LOGO_URL = 'https://img.alicdn.com/imgextra/i4/O1CN01nL5TZg28c4SBwOwl2_!!6000000007952-2-tps-108-108.png';

/** 批量导入模版地址 */
// eslint-disable-next-line max-len
export const BATCH_INVITE_EXAMPLE_URL = 'https://dingdoc-bucket-prod.oss-cn-hangzhou.aliyuncs.com/public-res/%E9%92%89%E9%92%89%E6%96%87%E6%A1%A3%E4%BC%81%E4%B8%9A%E7%89%88_%E9%80%9A%E8%AE%AF%E5%BD%95%E6%A8%A1%E6%9D%BF.xlsx';

/** 隐私政策 */
// eslint-disable-next-line max-len
export const PRIVACY_POLICY_URL = 'https://terms.alicdn.com/legal-agreement/terms/privacy_policy_full/20240802170817913/20240802170817913.html';

/** 服务协议 */
// eslint-disable-next-line max-len
export const SERVICE_AGREEMENT_URL = 'https://terms.alicdn.com/legal-agreement/terms/b_end_product_protocol/20240805100650552/20240805100650552.html';
