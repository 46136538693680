import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useEventListener, useMemoizedFn } from 'ahooks';
import { uniqueId } from 'lodash-es';
import cls from 'classnames';
import { VerifyCodeInputContainer } from './styles';

const CODE_NUM = 6;

interface VerifyCodeInputProps {
  onComplete?: (values: string[]) => void;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export interface VerifyCodeInputRef {
  reset: () => void;
  focus: (to?: number) => void;
}

export const VerifyCodeInput = forwardRef<VerifyCodeInputRef, VerifyCodeInputProps >((
  { onComplete, disabled, className, style }, ref,
) => {
  const [focus, setFocus] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [value, setValue] = useState<string[]>(Array(CODE_NUM).fill(''));
  const inputRef = useRef<HTMLInputElement>(null);

  useEventListener('blur', () => {
    setFocus(false);
  }, { target: inputRef.current });

  useEventListener('input', (e) => {
    const num: string = e.target.value || '';
    if (disabled || !focus) {
      return;
    }
    const newValue = padEnd(num.split(''), '', CODE_NUM);
    setValue(newValue);
    setCursor(num.length);
    if (num.length === CODE_NUM) {
      onComplete?.(newValue);
    }
  }, { target: inputRef.current });

  const handleFocus = useMemoizedFn(() => {
    inputRef.current?.focus();
    setFocus(true);
  });

  const handleReset = useMemoizedFn(() => {
    setValue(Array(CODE_NUM).fill(''));
    setCursor(0);
  });

  useImperativeHandle(ref, () => ({
    focus: handleFocus,
    reset: handleReset,
  }));

  return (
    <VerifyCodeInputContainer className={className} style={style}>
      {Array(CODE_NUM).fill(0).map((_, index) => {
        const active = index === cursor && !disabled && focus;
        return (
          <div
            key={uniqueId()}
            className={cls('verify-code-item', { active })}
            onClick={handleFocus}
          >
            {value[index]}
          </div>
        );
      })}
      <input
        ref={inputRef}
        className="hidden-input"
        inputMode="numeric"
        autoComplete="one-time-code"
        maxLength={CODE_NUM}
      />
    </VerifyCodeInputContainer>
  );
});

function padEnd<T>(arr: T[], value: T, len: number): T[] {
  if (arr.length >= len) {
    return arr;
  }
  return [...arr, ...Array(len - arr.length).fill(value)];
}
