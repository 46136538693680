import { Button } from '@ali/we-design-next';
import { COLORS } from '@ali/we-design-token';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
`;

export const FormItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .label {
    font-size: 14px;
    line-height: 20px;
    color: ${COLORS.LEVEL1};

    .required {
      color: ${COLORS.RED1};
      margin-right: 2px;
    }
  }

  .wdn-input {
    margin-top: 6px;
    height: 48px;
    background-color: ${COLORS.LEVEL5};
    border-color: transparent;

    input {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & + & {
    margin-top: 16px;
  }
`;

export const CreateBtn = styled(Button)`
  width: 100%;
  justify-content: center;

  &.wdn-button {
    height: 48px;
  }
`;
